<template>
  <!--

    Requisitos

    [x] Deve ter o mesmo tamanho do v-text-field
    [x] Deve permitir quebrar linha ou manter tudo na mesma linha
    [x] Deve permitir limitar o número de linhas e colocar os ... no final

  -->
  <KPage fluid>
    <!-- 
      KChip Cores como Atributo
     -->
    <v-row>
      <v-col cols="12">
        <SectionHeader title="KChip Cores como Atributo" />
      </v-col>
      <v-col cols="4">
        <KChip red>red</KChip>
        <KChip pink>pink</KChip>
        <KChip purple>purple</KChip>
        <KChip indigo>indigo</KChip>
        <KChip blue>blue</KChip>
        <KChip cyan>cyan</KChip>
        <KChip teal>teal</KChip>
        <KChip green>green</KChip>
        <KChip lime>lime</KChip>
        <KChip yellow>yellow</KChip>
        <KChip amber>amber</KChip>
        <KChip orange>orange</KChip>
        <KChip grey>grey</KChip>
      </v-col>
    </v-row>

    <!-- 
      KChip Cores com o atributo color
     -->
    <v-row>
      <v-col cols="12">
        <SectionHeader title="KChip Cores com o atributo color" />
      </v-col>
      <v-col cols="4">
        <KChip color="red">red</KChip>
        <KChip color="pink">pink</KChip>
        <KChip color="purple">purple</KChip>
        <KChip color="indigo">indigo</KChip>
        <KChip color="blue">blue</KChip>
        <KChip color="cyan">cyan</KChip>
        <KChip color="teal">teal</KChip>
        <KChip color="green">green</KChip>
        <KChip color="lime">lime</KChip>
        <KChip color="yellow">yellow</KChip>
        <KChip color="amber">amber</KChip>
        <KChip color="orange">orange</KChip>
        <KChip color="grey">grey</KChip>
      </v-col>
    </v-row>

    <!-- 
      KChip com tamanhos
     -->
    <v-row>
      <v-col cols="12">
        <SectionHeader title="KChip com tamanhos" />
      </v-col>
      <v-col cols="4">
        <KChip red>padrão</KChip>

        <KChip pink medium>medium</KChip>
      </v-col>
    </v-row>

    <!-- 
      KChip com cores personalizadas
     -->
    <v-row>
      <v-col cols="12">
        <SectionHeader title="KChip com cores personalizadas" />
      </v-col>
      <v-col cols="4">
        <KChip custom="#e74e05">#e74e05</KChip>
        <KChip custom="#9d800d" :alpha="0.1">#9d800d</KChip>
        <KChip custom="#FFCC00" :alpha="0.1">#FFCC00</KChip>
        <KChip custom="#63af00" :alpha="0.1">#63af00</KChip>
        <KChip custom="#09d32b" :alpha="0.1">#09d32b</KChip>
        <KChip custom="rgb(3, 175, 143)" :alpha="0.1">rgb(3, 175, 143)</KChip>
        <KChip custom="0c83e9" :alpha="0.1">0c83e9</KChip>
        <KChip custom="#0c13e9" :alpha="0.1">#0c13e9</KChip>
        <KChip custom="#940ce9" :alpha="0.1">#940ce9</KChip>
        <KChip custom="#e90caf" :alpha="0.1">#e90caf</KChip>
      </v-col>
    </v-row>
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import SectionHeader from "@/components/SectionHeader";
import KChip from "@/components/KChip";

export default {
  name: "PageKChip",

  components: {
    KPage,
    KChip,
    SectionHeader,
  },

  data() {
    return {};
  },
};
</script>

<style>
</style>

<template>
  <!--

    Requisitos

    [x] Deve suportar os mesmos atributos do autocomplete
    [x] Deve mostrar o nome do endereço em destaque e o endereço completo em menor tamanho
        tanto nos itens de seleção como no item já selecionado
    [x] Deve suportar alteração após finalizado o mounted
    [x] Deve suportar o v-model para entrada e para saída
    [x] Deve receber o array como lista 
    [x] Deve poder receber somente o id da pessoa
    [ ] Deve poder abrir, fechar e movimentar as opções com o teclado

  -->
  <KPage fluid>
    <!-- 
      KInputSelect simples
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader title="Select com subtexto padrão" />
      </v-col>

      <v-col cols="12">
        <v-select
          :items="items"
          outlined
          color="blue-grey lighten-2"
          label="Select"
          item-text="description"
          item-value="id"
          :multiple="false"
          v-model="form.input1"
        >
          <template v-slot:selection="data">
            <v-list-item v-bind="data.attrs" dense class="flex pl-0">
              <v-list-item-content class="py-0 flex">
                <v-list-item-title
                  v-html="data.item.description"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-html="data.item.street"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-content class="">
                <v-list-item-title
                  v-html="data.item.description"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-html="data.item.street"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <!-- 

     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader title="KInputSelect com endereço puro" />
      </v-col>
      <v-col cols="4">
        <KInputSelect
          item-value="id"
          item-text="text"
          item-subtext="subtext"
          clearable
          label="Selecione o Endereço"
          no-data-text="Selecione o proprietário para exibir os endereços"
          :items="calcItems"
          v-bind="inputAttrs"
          v-model="form.input1"
        />
      </v-col>
      <v-col cols="8">
        <ExpectedReceived v-model="form.input1" :expected="null" />
      </v-col>
    </v-row>
    <!-- 
      KInputSelect simples
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader title="KInputSelect simples" />
      </v-col>
      <v-col cols="4">
        <KInputSelect
          item-value="id"
          item-text="description"
          item-subtext="street"
          clearable
          label="Selecione o Endereço"
          no-data-text="Selecione o proprietário para exibir os endereços"
          :items="items"
          v-bind="inputAttrs"
          v-model="form.input1"
        />
      </v-col>
      <v-col cols="8">
        <ExpectedReceived v-model="form.input1" :expected="null" />
      </v-col>
    </v-row>

    <!-- 
      KInputSelect - Inicia com valor definido
     -->
    <v-row>
      <v-col cols="12">
        <SectionHeader title="KInputSelect - Inicia com valor definido" />
      </v-col>
      <v-col cols="4">
        <KInputSelect
          item-value="id"
          item-text="description"
          item-subtext="street"
          label="Selecione o Endereço"
          no-data-text="Selecione o proprietário para exibir os endereços"
          :items="items"
          v-bind="inputAttrs"
          v-model="form.input2"
        />
      </v-col>
      <v-col cols="8">
        <ExpectedReceived v-model="form.input2" :expected="3" />
      </v-col>
    </v-row>

    <!-- 
      KInputSelect - Inicia com valor definido em modo dark
     -->
    <v-row class="primary">
      <v-col cols="12">
        <SectionHeader
          class="white--text"
          title="KInputSelect - Inicia com valor definido"
        />
      </v-col>
      <v-col cols="4">
        <KInputSelect
          item-value="id"
          item-text="description"
          item-subtext="street"
          dark
          label="Selecione o Endereço"
          no-data-text="Selecione o proprietário para exibir os endereços"
          :items="items"
          v-bind="inputAttrs"
          v-model="form.input3"
        />
      </v-col>
      <v-col cols="8">
        <ExpectedReceived dark v-model="form.input3" :expected="3" />
      </v-col>
    </v-row>

    <!-- 
      KInputSelect - Tem seu valor redefinido após 1 segundo
     -->
    <v-row>
      <v-col cols="12">
        <SectionHeader
          title="KInputSelect - Tem seu valor redefinido após 1 segundo"
        />
      </v-col>
      <v-col cols="4">
        <KInputSelect
          item-value="id"
          item-text="description"
          item-subtext="street"
          label="Selecione o Endereço"
          no-data-text="Selecione o proprietário para exibir os endereços"
          :items="items"
          v-bind="inputAttrs"
          v-model="form.input4"
        />
      </v-col>
      <v-col cols="8">
        <ExpectedReceived v-model="form.input4" :expected="4" />
      </v-col>
    </v-row>

    <!-- 
      KInputSelect - Sem itens para exibir
     -->
    <v-row>
      <v-col cols="12">
        <SectionHeader title="KInputSelect - Sem itens para exibir" />
      </v-col>
      <v-col cols="4">
        <KInputSelect
          item-value="id"
          item-text="description"
          item-subtext="street"
          label="Selecione o Endereço"
          no-data-text="Selecione o proprietário para exibir os endereços"
          :items="[]"
          v-bind="inputAttrs"
          v-model="form.input5"
        />
      </v-col>
      <v-col cols="8">
        <ExpectedReceived v-model="form.input5" :expected="null" />
      </v-col>
    </v-row>

    <!-- 
      KInputSelect - A lista de itens é atualizada após 1 segundo
     -->
    <v-row>
      <v-col cols="12">
        <SectionHeader
          title="KInputSelect - A lista de itens é atualizada após 1 segundo"
        />
      </v-col>
      <v-col cols="4">
        <KInputSelect
          item-value="id"
          item-text="description"
          item-subtext="street"
          label="Selecione o Endereço"
          no-data-text="Selecione o proprietário para exibir os endereços"
          :items="emptens"
          v-bind="inputAttrs"
          v-model="form.input6"
        />
      </v-col>
      <v-col cols="8">
        <ExpectedReceived v-model="form.input6" :expected="3" />
      </v-col>
    </v-row>
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import SectionHeader from "@/components/SectionHeader";
import AddressSelect from "@/components/AddressSelect";
import ExpectedReceived from "./ExpectedReceived";
import KInputSelect from "@/components/KInput/Select";
import formatter from "@/components/AddressFormatter/formatter";

export default {
  name: "PageAddressSelect",

  components: {
    KPage,
    AddressSelect,
    KInputSelect,
    SectionHeader,
    ExpectedReceived
  },

  data() {
    const srcs = {
      1: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      2: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
      3: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
      4: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
      5: "https://cdn.vuetifyjs.com/images/lists/5.jpg"
    };
    return {
      autoUpdate: true,
      friends: ["Sandra Adams", "Britta Holt"],
      isUpdating: false,
      name: "Midnight Crew",

      people: [
        { header: "Group 1" },
        { name: "Sandra Adams", group: "Group 1", avatar: srcs[1] },
        { name: "Ali Connors", group: "Group 1", avatar: srcs[2] },
        { name: "Trevor Hansen", group: "Group 1", avatar: srcs[3] },
        { name: "Tucker Smith", group: "Group 1", avatar: srcs[2] },
        { divider: true },
        { header: "Group 2" },
        { name: "Britta Holt", group: "Group 2", avatar: srcs[4] },
        { name: "Jane Smith ", group: "Group 2", avatar: srcs[5] },
        { name: "John Smith", group: "Group 2", avatar: srcs[1] },
        { name: "Sandra Williams", group: "Group 2", avatar: srcs[3] }
      ],

      //
      form: {
        input1: null,
        input2: 3,
        input3: null,
        input4: null,
        input5: null,
        input6: 3
      },
      inputAttrs: { dense: false, outlined: true },
      items: [
        {
          id: 3,
          person_id: 2,
          description: "AP Principal",
          street: "Rua Desportista Jeremias Pinheiro da Câmara Filho",
          number: "500",
          complement: "AP 1204 ROMA - Villa Park",
          district: "Ponta Negra",
          zipcode: "59091250",
          city: "Natal",
          state: "RN",
          latitude: "",
          longitude: "",
          created_at: null,
          updated_at: null
        },
        {
          id: 4,
          person_id: 2,
          description: "Casa de Mainha",
          street: "Rua Maria Alves de Queiroz",
          number: "2685 ",
          complement: "",
          district: "Abolição IV",
          zipcode: "59614560",
          city: "Mossoró",
          state: "RN",
          latitude: "",
          longitude: "",
          created_at: null,
          updated_at: null
        }
      ],
      emptens: []
    };
  },

  computed: {
    calcItems() {
      return this.items.map(item => ({
        id: item.id,
        text: item.description,
        subtext: formatter(item)
      }));
    }
  },

  mounted() {
    setTimeout(() => {
      this.$set(this.form, "input4", 4);
      this.emptens = this.items;
    }, 1000);
  }
};
</script>

<style>
.k-input-select .v-select__selections {
  padding: 0 !important;
}
</style>

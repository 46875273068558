<!--

  KAction tem por objetivo unificar e padronizar a criação dos botões de ações das listas
  no sistema.

  Ele exibirá os botões um ao lado do outro com tooltip e quando estiver em telas pequenas
  irá mostrar apenas os 3 pontinhos e um menu se abrirá ao clicar

  <KAction
    :actions="[
      { label: 'Add', icon: 'mdi-plus', eventName: 'click:add' },
      { label: 'Editar', icon: 'mdi-pencil', eventName: 'click:edit' },
    ]"
  >
  
  
  </KAction>

  KAction
    :actions="[{ eventName:'click:add', label: 'Salvar', icon: 'mdi-plus', },]

  SectionHeader
    :actions="[
      {
        icon: 'mdi-plus',
        label: 'Clique para isso e aquilo',
        eventName: 'click:add',
      }

  SpeedDial
    :actions="[{ eventName:'click:add', label: 'Salvar', color: 'red', icon: 'mdi-save'},]

  KDialog
    :actions="[{ eventName:'click:add', label: 'Salvar', color: 'red', },]


-->
<template>
  <div v-if="isMobile">
    <v-btn icon @click.stop.prevent="show($event)">
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn>

    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      close-on-content-click
    >
      <v-list>
        <v-list-item
          v-for="action in actions"
          :key="action.icon"
          @click.stop.prevent="onItemClick(action)"
        >
          <v-list-item-title>
            <v-icon left>{{ action.icon }}</v-icon>
            {{ action.label }}</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </div>

  <v-row v-else>
    <v-tooltip v-for="action in actions" :key="action.icon" bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon @click.stop.prevent="onItemClick(action)" v-on="on">
          <v-icon>{{ action.icon }}</v-icon>
        </v-btn>
      </template>
      {{ action.label }}
    </v-tooltip>
  </v-row>
</template>

<script>
export default {
  name: "k-action",

  props: {
    // Opções dos botões
    actions: Array
  },

  data() {
    return {
      showMenu: false,
      x: null,
      y: null
    };
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },

  methods: {
    onItemClick(action) {
      console.log("click", action);
      if (action.eventName) this.$emit(action.eventName);
      this.showMenu = false;
    },

    show(e) {
      let { x, y } = e;
      console.log(x, y);
      e.preventDefault();
      this.showMenu = false;
      this.x = x;
      this.y = y;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    }
  }
};
</script>

<style>
</style>

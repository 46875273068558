<template>
  <!--

    Requisitos

    [x] Deve ter o mesmo tamanho do v-text-field
    [x] Deve permitir quebrar linha ou manter tudo na mesma linha
    [x] Deve permitir limitar o número de linhas e colocar os ... no final

  -->
  <KPage fluid>
    <!-- 
      KLabel simples
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader title="KLabel simples" subtitle="" />
      </v-col>
      <v-col cols="4">
        <KLabel label="Nome">José CLáudio Medeiros de Lima</KLabel>
      </v-col>
    </v-row>

    <!-- 
      KLabel simples com valor grande
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader title="KLabel simples com valor grande" subtitle="" />
      </v-col>
      <v-col cols="4">
        <KLabel label="Nome"
          >José CLáudio Medeiros de Lima mora na rua General Antonio Fernandes
          Dantas, 111 Centro - Assu/RN - CEP 59650-000</KLabel
        >
      </v-col>
    </v-row>

    <!-- 
      KLabel - truncate=true
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader title="KLabel - truncate=true" subtitle="" />
      </v-col>
      <v-col cols="12" sm="4">
        <KLabel label="Nome" truncate
          >José CLáudio Medeiros de Lima mora na rua General Antonio Fernandes
          Dantas, 111 Centro - Assu/RN - CEP 59650-000</KLabel
        >
      </v-col>
    </v-row>

    <!-- 
      KLabel - lines=2
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader title="KLabel - lines=2" subtitle="" />
      </v-col>
      <v-col cols="12" sm="4">
        <KLabel label="Nome" lines="2"
          >José CLáudio Medeiros de Lima mora na rua General Antonio Fernandes
          Dantas, 111 Centro - Assu/RN - CEP 59650-000</KLabel
        >
      </v-col>
    </v-row>

    <!-- 
      KLabel - Tamanho ao lado de um text-field com dense=true e hide-details=true
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader
          title="KLabel - Tamanho ao lado de um text-field com dense=true e hide-details=true"
          subtitle=""
        />
      </v-col>
      <v-col cols="6">
        <KLabel label="Nome" truncate>José CLáudio Medeiros de Lima</KLabel>
      </v-col>
      <v-col cols="6">
        <v-text-field hide-details value="cla" label="Email" dense outlined>
        </v-text-field>
      </v-col>
    </v-row>
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import SectionHeader from "@/components/SectionHeader";
import KLabel from "@/components/KLabel";

export default {
  name: "PageKLabel",

  components: {
    KPage,
    KLabel,
    SectionHeader
  },

  data() {
    return {};
  }
};
</script>

<style>
</style>

<template>
  <v-list-item-content class="flex">
    <v-list-item-title>{{ item.description }} </v-list-item-title>
    <v-list-item-subtitle class="caption">
      <AddressFormatter v-bind="item" />
    </v-list-item-subtitle>
  </v-list-item-content>
</template>

<script>
import AddressFormatter from "../AddressFormatter";
export default {
  props: {
    item: Object
  },

  components: {
    AddressFormatter
  }
};
</script>

<style>
</style>

<template>
  <v-select
    class="address-select"
    item-value="id"
    :items="items"
    v-model="model"
    v-bind="$attrs"
    :menu-props="{ overflowY: true }"
  >
    <!-- Item selecionado -->
    <template v-slot:selection="{ item }">
      <v-list-item dense class="pl-1 flex">
        <AddressSelectItem :item="item" />
      </v-list-item>
    </template>

    <!-- Itens da lista -->
    <template v-slot:item="{ item }">
      <AddressSelectItem :item="item" />
    </template>
  </v-select>
</template>

<script>
import AddressFormatter from "../AddressFormatter";
import AddressSelectItem from "./item";

export default {
  components: {
    AddressFormatter,
    AddressSelectItem
  },

  props: {
    value: [Number],
    items: Array
  },

  data: () => ({
    model: {}
  }),

  watch: {
    value(val) {
      this.model = val;
    },
    model(val) {
      this.$emit("input", val);
    }
  },

  mounted() {
    this.model = this.value;
  }
};
</script>

<style>
.address-select .v-select__selections input {
  display: none;
}
</style>

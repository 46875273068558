<template>
  <KPage fluid>
    <!-- 
      Inicia com v-model=null
      - O valor exibido no componente deve estar vazio
      - O valor exibido ao lado do componente deve estar vazio
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader
          title="KInputMonth simples"
          subtitle="Inicia com valor=null. O valor exibido no componente deve estar vazio"
        />
      </v-col>
      <v-col cols="4">
        <KInputMonth v-model="input1" v-bind="bind" />
      </v-col>
      <v-col cols="8">
        <!--  -->
        <ExpectedReceived v-model="input1" :expected="null" />
      </v-col>
    </v-row>

    <!-- 
      Inicia com v-model=null E mandatory=true
      - O valor recebido deve ser o mês atual
      - O valor esperado deve ser o mês atual
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader
          title="KInputMonth mandatory"
          subtitle="Inicia com valor=null e mandatory=true"
        />
      </v-col>

      <v-col cols="4">
        <KInputMonth v-model="input2" mandatory v-bind="bind" />
      </v-col>
      <!--  -->
      <v-col cols="8">
        <ExpectedReceived v-model="input2" expected="2022-03" />
      </v-col>
    </v-row>

    <!-- 
      Inicia com v-model="2022-05"
      - O valor exibido no componente deve ser "Maio de 2022"
      - O valor exibido ao lado do componente deve 2022-05
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader
          title="KInputMonth inicia com um valor pré-definido"
          subtitle="Inicia com valor=2022-05"
        />
      </v-col>
      <v-col cols="4">
        <KInputMonth v-model="input3" mandatory v-bind="bind" />
      </v-col>
      <v-col cols="8">
        <!--  -->
        <ExpectedReceived v-model="input3" expected="2022-05" />
      </v-col>
    </v-row>

    <!-- 
      Inicia com v-model="2022-05"
      - O valor exibido no componente deve ser "Maio de 2022"
      - O valor exibido ao lado do componente deve 2022-05
      - Deve permitir limpar o valor
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader
          title="KInputMonth clerable=true - Deve-se limpar para obter null no v-model"
          subtitle="Inicia com valor=2022-05"
        />
      </v-col>
      <v-col cols="4">
        <KInputMonth v-model="input4" clearable v-bind="bind" />
      </v-col>
      <v-col cols="8">
        <!--  -->
        <ExpectedReceived v-model="input4" :expected="null" />
      </v-col>
    </v-row>

    <!-- 
      Inicia com v-model="{year: 2022, month: 5}"
      - O valor exibido no componente deve ser "Maio de 2022"
      - Deve retornar o valor '2022-05'
      - Deve permitir limpar o valor
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader
          title="KInputMonth com v-model {year:2022, month:5}"
          subtitle="Deve retornar a string '2022-05'"
        />
      </v-col>
      <v-col cols="4">
        <KInputMonth v-model="input5" clearable v-bind="bind" />
      </v-col>
      <v-col cols="8">
        <!--  -->
        <ExpectedReceived v-model="input5" expected="2022-05" />
      </v-col>
    </v-row>

    <!-- 
      Inicia com v-model="{year: 2022, month: 5}"
      - O valor exibido no componente deve ser "Maio de 2022"
      - Deve retornar o valor {year:2022, month:5}
      - Deve permitir limpar o valor
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader
          title="KInputMonth com v-model {year:2022, month:5} e returnObject=true"
          subtitle="Deve retornar o objeto {year: 2022, month: 05}"
        />
      </v-col>
      <v-col cols="4">
        <KInputMonth v-model="input6" return-object clearable v-bind="bind" />
      </v-col>
      <v-col cols="8">
        <!--  -->
        <ExpectedReceived
          v-model="input6"
          :expected="{ year: 2022, month: 5 }"
        />
      </v-col>
    </v-row>

    <!-- 
      Inicia com v-model="2022-10"
      - O valor exibido no componente deve ser "Outubro de 2022"
      - Deve retornar o valor {year:2022, month:10}
      - Deve permitir limpar o valor
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader
          title="KInputMonth com v-model '2022-10' e returnObject=true"
          subtitle="Deve retornar o objeto {year:2022, month:10}"
        />
      </v-col>
      <v-col cols="4">
        <KInputMonth v-model="input7" return-object clearable v-bind="bind" />
      </v-col>
      <v-col cols="8">
        <!--  -->
        <ExpectedReceived
          v-model="input7"
          :expected="{ year: 2022, month: 10 }"
        />
      </v-col>
    </v-row>

    <!-- 
      To do
      

      [x] O atributo mandatory força o início do componente com um valor já preenchido

      [x] O atributo clearable permite que o valor do componente seja limpo

      [ ] KInputMonth pode receber um objeto de {year:2022, month:5} no v-model
      
      [ ] KInputMonth pode ser configurado para retornar o valor como objeto {year:2022, month:5} 
      e não como a string 2022-05
     -->
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import SectionHeader from "@/components/SectionHeader";
import KInputMonth from "@/components/KInput/Month";

import ExpectedReceived from "./ExpectedReceived";

export default {
  name: "PageInputMonth",

  components: {
    KPage,
    KInputMonth,
    SectionHeader,
    ExpectedReceived
  },

  data() {
    return {
      // Valores dos inputs
      input1: null,
      input2: null, // mandatory=true
      input3: "2022-05", //
      input4: "2022-05", //clearable=true / deve permitir limpar
      input5: { year: 2022, month: 5 }, // inicia como object / retorno como string
      input6: { year: 2022, month: 5 }, // inicia como object / retorno como object
      input7: "2022-10", // inicia como string / retorno como object

      // Configurações do v-bind
      bind: {
        outlined: true,
        rounded: false
        // dense: true
      }
    };
  }
};
</script>

<style>
</style>

<template>
  <v-card
    :dark="isDark"
    outlined
    :style="{
      'border-color': color,
      'background-color': 'transparent',
    }"
  >
    <v-card-text class="py-2">
      <v-row>
        <v-col cols="1" class="d-flex justify-center align-center">
          <v-icon v-if="isMatch" color="green">mdi-check</v-icon>
          <v-icon v-if="!isMatch" color="red">mdi-cancel</v-icon>
        </v-col>
        <v-col cols="5">
          <p class="my-0">v-model: {{ JSON.stringify(value) }}</p>
          <p class="my-0 font-italic caption">{{ typeof value }}</p>
        </v-col>
        <v-col cols="6">
          <p class="my-0">Esperado: {{ JSON.stringify(expected) }}</p>
          <p class="my-0 font-italic caption">{{ typeof expected }}</p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    value: [String, Object, Number],
    expected: [String, Object, Number],
    dark: Boolean
  },

  computed: {
    isDark() {
      return this.dark || this.$vuetify.theme.isDark;
    },
    isMatch() {
      const value =
        typeof this.value === "object"
          ? JSON.stringify(this.value)
          : this.value;

      const expected =
        typeof this.expected === "object"
          ? JSON.stringify(this.expected)
          : this.expected;

      return value === expected;
    },
    color() {
      return this.isMatch ? "green" : "red";
    }
  }
};
</script>

<template>
  <KPage>
    <v-list>
      <v-list-item to="/" v-for="item in [1, 2, 3, 4, 5, 6, 7, 8]" :key="item">
        <v-list-item-content>
          <v-list-item-title>José Cláudio Medeiros de Lima</v-list-item-title>
          <v-list-item-subtitle>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            Recusandae, a? Modi veniam dolorem labore exercitationem adipisci
            unde necessitatibus commodi corrupti fugit. Enim adipisci expedita
            cum.
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <KAction :actions="actions" @click:edit="onActionClick" />
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import KAction from "@/components/KAction";

export default {
  components: {
    KPage,
    KAction
  },

  data() {
    return {
      actions: [
        { label: "Editar", icon: "mdi-pencil", eventName: "click:edit" },
        { label: "Ir para a página", icon: "mdi-chevron-right", to: "/" },
        {
          label: "Abrir em outra aba",
          icon: "mdi-open-in-new",
          href: "/",
          target: "_blank"
        }
      ]
    };
  },

  methods: {
    onActionClick(e) {
      console.log(e);
    }
  }
};
</script>

<style>
</style>

<template>
  <!--

    Requisitos

    [x] Deve suportar um array de máscaras para escolher entre 
        uma ou outra de acordo com a quantidade de caracteres
    [x] Deve aplicar a máscara quando o v-model vier sem a máscara
    [x] Deve ter a opção de retornar o valor com ou sem a máscara
    [x] Deve aplicar a máscara quando colar dentro
    [x] Deve ter um evento @finished para permitir alguma ação, tipo pular o campo
    [x] Deve funcionar os atributos do vuetify para o v-text-field, como o rules
    [x] Deve parar de aceitar digitação se atingir o completed



  -->
  <KPage fluid>
    <!-- 
      Máscara de CEP
      - Retorna o valor com a máscara
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader
          title="KInputMasked - CEP"
          subtitle="Deve retornar o valor com a máscara"
        />
      </v-col>
      <v-col cols="4">
        <KInputMasked
          v-model="form.input1"
          v-bind="bind"
          label="CEP"
          mask="99.999-999"
          returnMasked
        />
      </v-col>
      <v-col cols="8">
        <!--  -->
        <ExpectedReceived v-model="form.input1" expected="59.650-000" />
      </v-col>
    </v-row>

    <!-- 
      Máscara de CEP
      - Retorna o valor sem a máscara
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader
          title="KInputMasked - CEP"
          subtitle="Deve retornar o valor sem a máscara"
        />
      </v-col>
      <v-col cols="4">
        <KInputMasked
          v-model="form.input2"
          v-bind="bind"
          label="CEP"
          mask="99.999-999"
        />
      </v-col>
      <v-col cols="8">
        <!--  -->
        <ExpectedReceived v-model="form.input2" expected="59650000" />
      </v-col>
    </v-row>

    <!-- 
      
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader
          title="KInputMasked - CPF/CNPJ"
          subtitle="Altera o valor 1 segundo após o evento mounted"
        />
      </v-col>
      <v-col cols="4">
        <KInputMasked
          v-model="form.input7"
          v-bind="bind"
          :returnMasked="true"
          label="CPF/CNPJ"
          mask="999.999.999-99"
        />
      </v-col>
      <v-col cols="8">
        <!--  -->
        <ExpectedReceived v-model="form.input7" expected="012.345.678-90" />
      </v-col>
    </v-row>

    <!-- 
      Máscara de CPF/CNPJ
      - Retorna o valor sem a máscara
      - Máscara no formato de array
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader
          title="KInputMasked - CPF/CNPJ"
          subtitle="Deve alternar entre as duas máscaras"
        />
      </v-col>
      <v-col cols="4">
        <KInputMasked
          v-model="form.input3"
          v-bind="bind"
          :returnMasked="false"
          label="CPF/CNPJ"
          :mask="['999.999.999-99', '99.999.999/9999-99']"
        />
      </v-col>
      <v-col cols="8">
        <!--  -->
        <ExpectedReceived v-model="form.input3" expected="01290582467" />
      </v-col>
    </v-row>

    <!-- 
      Máscara de CPF/CNPJ
      - Retorna o valor sem a máscara
      - Máscara no formato de array
      - Inicia com um valor preenchido sem máscara
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader
          title="KInputMasked - CPF/CNPJ"
          subtitle="Inicia com um valor preenchido sem máscara"
        />
      </v-col>
      <v-col cols="4">
        <KInputMasked
          v-model="form.input4"
          v-bind="bind"
          :returnMasked="false"
          label="CPF/CNPJ"
          :mask="['999.999.999-99', '99.999.999/9999-99']"
        />
      </v-col>
      <v-col cols="8">
        <!--  -->
        <ExpectedReceived v-model="form.input4" expected="01290582467" />
      </v-col>
    </v-row>

    <!-- 
      Máscara de CPF/CNPJ
      - Retorna o valor sem a máscara
      - Máscara no formato de array
      - Inicia com um valor preenchido sem máscara
      - Valida enquanto digita
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader
          title="KInputMasked - CPF/CNPJ"
          subtitle="Valida a máscara enquanto digita"
        />
      </v-col>
      <v-col cols="4">
        <KInputMasked
          v-model="form.input5"
          v-bind="bind"
          :returnMasked="true"
          label="CPF/CNPJ"
          mask="999.999.999-99"
          validateMask
        />
      </v-col>
      <v-col cols="8">
        <!--  -->
        <ExpectedReceived v-model="form.input5" expected="012.905.824-67" />
      </v-col>
    </v-row>

    <!-- 
      Máscara de CPF/CNPJ
      - Retorna o valor sem a máscara
      - Máscara no formato de array
      - Inicia com um valor preenchido sem máscara
      - Valida enquanto digita
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader
          title="KInputMasked - CPF/CNPJ"
          subtitle="Valida a máscara enquanto digita e se o CPF está válido"
        />
      </v-col>
      <v-col cols="4">
        <KInputMasked
          v-model="form.input6"
          v-bind="bind"
          :returnMasked="true"
          label="CPF/CNPJ"
          mask="999.999.999-99"
          validateMask
          :rules="[$validation.isCPF]"
        />
      </v-col>
      <v-col cols="8">
        <!--  -->
        <ExpectedReceived v-model="form.input6" expected="012.905.824-67" />
      </v-col>
    </v-row>
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import SectionHeader from "@/components/SectionHeader";
import KInputMasked from "@/components/KInput/Masked";

import ExpectedReceived from "./ExpectedReceived";

export default {
  name: "PageInputMasked",

  components: {
    KPage,
    KInputMasked,
    SectionHeader,
    ExpectedReceived
  },

  data() {
    return {
      // Valores dos inputs
      form: {
        input1: "59650000",
        input2: "59.650-000",
        input3: "01290582467",
        input4: "01290582467",
        input5: "01290582467",
        input6: "012.905.824-67",
        input7: null
      },

      // Configurações do v-bind
      bind: {
        outlined: true,
        rounded: false
      }
    };
  },

  mounted() {
    setTimeout(() => {
      // Altera o input7 após o carregamento
      this.form.input7 = "01234567890";
    }, 1000);
  }
};
</script>

<style>
</style>

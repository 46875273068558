<template>
  <!--

    Requisitos

    [ ] 

  -->
  <KPage>
    <!-- 
      KAlert simples
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader title="KAlert simples" subtitle="" />
      </v-col>
      <v-col cols="12">
        <KAlert>José Cláudio Medeiros de Lima</KAlert>
      </v-col>
    </v-row>

    <!-- 
      KAlert simples
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader title="KAlert - cores" subtitle="" />
      </v-col>
      <v-col cols="12">
        <KAlert info>José Cláudio Medeiros de Lima</KAlert>
      </v-col>
      <v-col cols="12">
        <KAlert error>José Cláudio Medeiros de Lima</KAlert>
      </v-col>
      <v-col cols="12">
        <KAlert success>José Cláudio Medeiros de Lima</KAlert>
      </v-col>
      <v-col cols="12">
        <KAlert warning>José Cláudio Medeiros de Lima</KAlert>
      </v-col>
    </v-row>

    <!-- 
      KAlert simples
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader title="KAlert - sem ícone" subtitle="" />
      </v-col>
      <v-col cols="12">
        <KAlert no-icon>José Cláudio Medeiros de Lima</KAlert>
      </v-col>
    </v-row>

    <!-- 
      KAlert simples
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader title="KAlert - com título" subtitle="" />
      </v-col>
      <v-col cols="12">
        <KAlert warning title="Atenção">José Cláudio Medeiros de Lima</KAlert>
      </v-col>
    </v-row>

    <!-- 
      KAlert simples
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader title="KAlert - com título e sem ícone" subtitle="" />
      </v-col>
      <v-col cols="12">
        <KAlert title="Informação" info no-icon
          >José Cláudio Medeiros de Lima</KAlert
        >
      </v-col>
    </v-row>
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import SectionHeader from "@/components/SectionHeader";
import KAlert from "@/components/KAlert";

export default {
  name: "PageKAlert",

  components: {
    KPage,
    KAlert,
    SectionHeader
  },

  data() {
    return {};
  }
};
</script>

<style>
</style>

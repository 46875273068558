<template>
  <v-autocomplete
    auto-select-first
    no-data-text="Digite para pesquisar os resultados"
    no-filter
    return-object
    item-value="id"
    item-text="name"
    :label="label"
    :rules="rules"
    :items="list"
    :loading="loading"
    :search-input.sync="search"
    v-model="selected"
    v-bind="$attrs"
  >
    <!-- Template dos itens da lista  -->
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title v-html="data.item.name"></v-list-item-title>
        <v-list-item-subtitle>
          {{ data.item.cpf | mask("###.###.###-##") }}
          {{ data.item.cnpj | mask("##.###.###/####-##") }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "people-autocomplete",
  props: {
    value: Number,
    rules: Array,
    label: String,
    timeout: { type: [Number || String], default: 300 },
    searchParams: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      search: "",
      loading: false,
      list: [],
      selected: null,
      timeoutObject: null //timeout entre as tentativas de conexão
    };
  },

  watch: {
    // Ao alterar o valor de fora pra dentro
    value(newVal, oldVal) {
      // Limpa o selected para não achar que está com alguma
      // coisa selecionada sem estar
      if (!newVal) {
        this.selected = null;
      }

      // Caso o valor seja atualizado de fora pra dentro, faça uma busca para
      // preenche os novos dados
      if (newVal && !this.selected) {
        // console.log("Recebido um id de fora", newVal);
        this.fetchInitial(newVal);
      }
    },
    search(val) {
      // Sempre que entra, limpa a anterior. Então ficará só limpando
      // enquanto o método estiver sendo chamado, ou seja, enquanto
      // o usuário estiver digitando.
      // Quando ele parar de digitar, aguarda o timeout e executa a função
      clearTimeout(this.timeoutObject);

      this.timeoutObject = setTimeout(() => {
        val && val !== this.select && this.fetch(val);
      }, this.timeout);
    },

    selected(people) {
      // Personaliza a exibição da cidade
      // if (people) {
      // people.city = `${people.city}/${people.uf}`;
      // }

      // console.log("peopleautocomplete", people);
      if (people) {
        this.$emit("input", people.id);
        this.$emit("select", people);
      } else {
        this.$emit("input", null);
        this.$emit("select", null);
      }
    }
  },

  computed: {},
  methods: {
    /**
     * Faz a busca pelo nome
     */
    async fetch() {
      this.loading = true;

      const query = { q: this.search, ...this.searchParams };

      const list = (await this.$api.get(`/people`, query)).data.data.data;

      this.list = list;

      this.loading = false;
    },

    /**
     * Faz a busca pelo id
     */
    async fetchInitial(id) {
      if (id) {
        this.loading = true;
        const query = { id, ...this.searchParams };
        const result = await this.$api.get("/people", query);
        const selected = result.data.data;
        // this.search = selected.name;
        this.list = [selected];
        this.selected = selected;
      }
    }
  },

  mounted() {
    if (this.value) {
      this.fetchInitial(this.value);
    }
  }
};
</script>

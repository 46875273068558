<template>
  <!--

    Requisitos

  -->
  <KPage fluid>
    <!--  -->
    <v-row>
      <v-col cols="12">
        <SectionHeader title="KInputMoney simples" />
      </v-col>
      <v-col cols="4">
        <KInputMoney v-bind="bind" label="Valor" v-model="input1" />
      </v-col>
    </v-row>

    <!--  -->
    <v-row>
      <v-col cols="12">
        <SectionHeader title="KInputMoney - precision=3" />
      </v-col>
      <v-col cols="4">
        <KInputMoney
          v-bind="bind"
          label="Valor"
          v-model="input2"
          :precision="3"
        />
      </v-col>
    </v-row>

    <!--  -->
    <v-row>
      <v-col cols="12">
        <SectionHeader title="KInputMoney - inicia com valor null" />
      </v-col>
      <v-col cols="4">
        <KInputMoney v-bind="bind" label="Valor" v-model="input3" />
      </v-col>
    </v-row>

    <!--  -->
    <v-row>
      <v-col cols="12">
        <SectionHeader title='KInputMoney - inicia com valor = ""' />
      </v-col>
      <v-col cols="4">
        <KInputMoney v-bind="bind" label="Valor" v-model="input4" />
      </v-col>
    </v-row>

    <!--  -->
    <v-row>
      <v-col cols="12">
        <SectionHeader title="KInputMoney - inicia com valor = 5.84" />
      </v-col>
      <v-col cols="4">
        <KInputMoney v-bind="bind" label="Valor" v-model="input5" />
      </v-col>
    </v-row>

    <!--  -->
    <v-row class="theme--dark grey darken-4">
      <v-col cols="12">
        <SectionHeader title="KInputMoney - Modo dark" class="white--text" />
      </v-col>
      <v-col cols="4">
        <KInputMoney dark v-bind="bind" label="Valor" v-model="input6" />
      </v-col>
    </v-row>

    <!--  -->
    <v-row class="">
      <v-col cols="12">
        <SectionHeader
          title="KInputMoney - Remove e reinsere no DOM com o v-if"
        />
      </v-col>
      <v-col cols="4">
        <KInputMoney v-if="show" v-bind="bind" label="Valor" v-model="input7" />
      </v-col>
      <v-col cols="4">
        <v-switch v-model="show" inset></v-switch>
      </v-col>
    </v-row>

    <!--  -->
    <v-row class="">
      <v-col cols="12">
        <SectionHeader title="KInputMoney - Teste dentro de um loop" />
      </v-col>
      <v-col cols="4" v-for="i in [1, 2, 3]" :key="i">
        <KInputMoney
          v-if="show"
          v-bind="bind"
          label="Valor"
          v-model="input8[i]"
        />
      </v-col>
    </v-row>
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import SectionHeader from "@/components/SectionHeader";
import KInputMoney from "@/components/KInput/Money";

export default {
  name: "PageKInputMoney",

  components: {
    KPage,
    KInputMoney,
    SectionHeader
  },

  data() {
    return {
      bind: { rounded: false, dense: true, outlined: true },
      show: true,

      //
      input1: null,
      input2: null,
      input3: null,
      input4: "",
      input5: 5.84,
      input6: 5.84,
      input7: 5.84,
      input8: []
    };
  }
};
</script>

<style>
</style>

<template>
  <KPage fluid>
    <!--

    Requisitos

    [ ] Deve exibir vazio caso não tenha id
    [ ] Deve pesquisar a pessoa caso tenha um id
    [ ] Deve evitar fazer pesquisas desnecessárias quando já tiver o id
    [ ] Deve emitir o evento com todos os dados do objeto ao selecionar
    [ ] Deve permitir limpar
    [ ] Deve exibir vários resultados quando existirem homônimos
    [ ] Deve 
    [ ] Deve 
    
    -->

    <!-- 
      PeopleAutocomplete
     -->

    <v-row>
      <v-col cols="12">
        <SectionHeader title="PeopleAutocomplete" />
      </v-col>

      <v-col cols="4">
        <PeopleAutocomplete v-bind="binds" v-model="data.id1" />
      </v-col>
      <v-col cols="8">
        <ExpectedReceived v-model="data.id1" :expected="1" />
      </v-col>
    </v-row>
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import SectionHeader from "@/components/SectionHeader";
import PeopleAutocomplete from "@/components/PeopleAutocomplete";
//
import ExpectedReceived from "./ExpectedReceived";

export default {
  components: {
    KPage,
    SectionHeader,
    ExpectedReceived,
    PeopleAutocomplete
  },

  data() {
    return {
      data: {
        id1: 81
      },
      binds: {
        outlined: true,
        dense: true
      }
    };
  },

  methods: {
    onActionClick(e) {
      console.log(e);
    }
  }
};
</script>

<style>
</style>
